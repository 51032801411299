// apply extra selector to overrule Juicer CSS file loaded after or CSS file
body {
    .j-poster {
        display: none;
    }

    .juicer-feed {
        color: $textColor;
        font-family: unset;
        font-size: pxToRem(18);

        @include small-or-larger {
            font-size: pxToRem(20);
        }

        a {
            color: $linkColor;
            transition: none;
        }

        // with H1 to overrule style until we have changed the element type with JavaScript
        .referral, h1.referral {
            margin: 0 0 pxToRem(16);
            font-size: pxToRem(16);
            line-height: 1.2;
            text-align: center;

            a {
                @include font($defaultFontFamily, 'regular');

                color: $textColor;
                font-size: pxToRem(16);

                @include small-or-larger {
                    font-size: pxToRem(18);
                }

                // copy of default CSS because it is bound to H1
                &::after {
                    position: relative;
                    top: -1px;
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    margin-left: 10px;
                    background: url('https://assets.juicer.io/logo-without-text.svg') no-repeat;
                    vertical-align: middle;
                    content: '';
                }
            }
        }

        .j-facebook-bg {
            background-color: transparent;
        }

        .feed-item {
            border: 1px solid $borderColor;
        }

        // similar to the secondary button
        .j-read-more {
            @include font($highlightFontFamily, 'bold');

            display: block;
            margin: 1.5em auto 0;
            padding: 0;
            font-size: pxToRem(20);
            letter-spacing: 0.01em;
            text-align: center;
            text-transform: uppercase;
        }

        // similar to the primary button
        .j-paginate {
            @include font($highlightFontFamily, 'bold');

            width: 12em; // for IE11
            width: max-content;
            margin: 3.75rem auto 0;
            border: pxToRem(2) solid $themeColor;
            border-radius: 0;
            padding: pxToRem(18) pxToRem(38);
            color: $white;
            background-color: $themeColor;
            font-size: pxToRem(20);
            letter-spacing: 0.01em;
            transition: none;

            &:hover {
                color: $themeColor;
                background-color: $white;
            }
        }

        .j-facebook-text-hover:hover::before, .j-instagram-text-hover:hover::before, .j-pinterest-text-hover:hover::before {
            color: $textColor;
        }

        &.image-grid {
            .j-meta {
                position: static;
                margin: 1.5em 0 0;
                padding: 0;

                a {
                    color: $textColor;
                }
            }

            .j-text {
                position: static;
                padding: 2em;
                color: $textColor;
                background-color: transparent;
                text-align: unset;
                visibility: visible;
                opacity: 1;
                transition: none;

                .j-message {
                    a {
                        color: $linkColor;

                        &:hover {
                            // need to override Juicer !important
                            // stylelint-disable-next-line declaration-no-important
                            text-decoration: underline !important;
                        }
                    }

                    p {
                        color: $textColor;
                    }
                }
            }
        }

        &.colored-icons .j-meta a.j-social {
            &.j-facebook, &.j-instagram, &.j-pinterest {
                color: $textColor;
            }
        }
    }
}
