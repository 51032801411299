@import '~swiper/swiper';

.swiper-control {
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 pxToRem(40);

    @include huge-or-larger {
        padding-left: 10rem;
    }

    .swiper-button-group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: right;
        width: 6.5rem;

        button {
            border: none;
            padding: 0;
            background-color: transparent;
        }

        .swiper-button-pause {
            box-sizing: content-box;
            margin-right: auto;

            &[data-state='play'] {
                width: pxToRem(4);
                height: pxToRem(20);
                border-right: $textColor solid pxToRem(6);
                border-left: $textColor solid pxToRem(6);

                .play {
                    display: none;
                }
            }

            &[data-state='pause'] {
                width: pxToRem(13);
                height: pxToRem(13);
                margin-left: pxToRem(-5);
                border-top: $textColor solid pxToRem(6);
                border-right: $textColor solid pxToRem(6);
                transform: rotate(45deg);

                .pause {
                    display: none;
                }
            }
        }

        .swiper-button-prev {
            box-sizing: border-box;
            width: pxToRem(18);
            height: pxToRem(18);
            border-top: $textColor solid pxToRem(2);
            border-left: $textColor solid pxToRem(2);
            transform: rotate(-45deg);
        }

        .swiper-button-next {
            box-sizing: border-box;
            width: pxToRem(18);
            height: pxToRem(18);
            margin-left: 1rem;
            border-top: $textColor solid pxToRem(2);
            border-right: $textColor solid pxToRem(2);
            transform: rotate(45deg);
        }
    }

    .swiper-pagination {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;
        list-style-type: none;

        li {
            padding-left: 0.5em;

            button {
                box-sizing: border-box;
                width: pxToRem(16);
                height: pxToRem(16);
                margin: pxToRem(2);
                border: $textColor solid pxToRem(2);
                padding: 0;
            }

            &.swiper-pagination-bullet-active {
                button {
                    background: $textColor;
                }
            }
        }
    }
}

.big-slider {
    @include huge-or-larger {
        max-width: pxToRem(1920);
    }

    .swiper-control {
        position: absolute;
        top: calc(100vw / 16 * 9);
        left: 0;
        width: 100%;
        margin-top: 1em;

        @include small-or-larger {
            top: initial;
            bottom: pxToRem(32);
            width: 50%;
            margin-top: 0;
        }
    }
}

.big-slider-slide {
    display: flex;
    flex-direction: column;

    @include small-or-larger {
        flex-direction: row;
    }

    & > * {
        flex: 1 0 50%;
        margin: 0;
        padding: 0;
    }
}

.big-slider-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    order: 1;
    box-sizing: border-box;
    padding: pxToRem(40);
    padding-top: pxToRem(80);
    background-color: $backgroundColor;

    @include huge-or-larger {
        padding-left: 10rem;
    }

    h2 {
        margin: 0 0 0.5em 0;
        font-size: pxToRem(40);
        text-align: left;
    }

    p {
        font-weight: fontWeight('semi-bold');
        line-height: 1.75;
    }

    @include small-or-larger {
        order: 0;
        padding-top: pxToRem(40);
        padding-bottom: pxToRem(100);
    }

    @include extra-large-or-larger {
        h2 {
            font-size: pxToRem(55);
        }
    }

    & > * {
        max-width: max-content; // don't expand block so white space can be used for swiping
    }
}

.big-slider-media {
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.big-slider-video {
    @include aspect-ratio(16, 9);

    video {
        width: 100%;
        height: 100%;
    }
}
